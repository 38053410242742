.container-search-form {
  width: 95vw;
  max-width: 1000px;
	margin: auto;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 15px;
  background-color: #F2F2F2;
}

.container-search {
  position: relative;
  background-color: #F2F2F2;
  color: black;
  width: 100%;
  max-width :100%;
	margin: auto;
	padding-left: 16px;
	padding-right: 16px;
}

.search-row {
  margin: 0 !important;
  margin-bottom: 10px;
}

.flex-col {
  flex-direction: column;
}

.user-details-table {
  background-color: #F2F2F2;
  border: none !important;
  appearance: none !important;
  width: 90vw;
  padding: 0;
  margin: auto;
  min-height: 500px;
}

.resend-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: underline;
  display: inline;
  margin: 0;
  padding: 0;
	color: black;
	text-align: center;
}

.resend-button:hover,
.resend-button:focus {
	text-decoration: none;
	background-color: transparent;
	outline: none;
	color: black;
}

.error-text {
  font-family: "Roboto Condensed";
  font-size: 14px;
  font-stretch: condensed;
  color: red;
  margin-top: 0;
  text-align: center;
  width: 100%;
  margin-top: 5px;
}