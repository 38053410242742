.navbar {
  z-index:1;
}

.nav-brand {
	background-image: url('../../../assets/images/ddp-logo.svg');
	background-position: bottom;
	background-repeat: no-repeat;
	height: 60px;
	width: 120px;
}

.dropdown-menu {
  background-color: #f9f9f9 !important;
  border-radius: 0px;
  opacity: .9;
  outline: none !important;
  /* z-index: 5; */
}


.dropdown-menu:active{
  outline: none !important;
}

.dropdown-toggle::after {
	content : none !important;
}

.dropdown-toggle::before {
	content : none !important;
}

.dropdown-item {
  outline: none !important;
  box-shadow:none !important;
}

.dropdown-item:focus {
  outline: none !important;
  box-shadow:none !important;
  /* z-index: 5; */

}

.dropdown-item:active {
  outline: none !important;
  box-shadow:none !important;
}


.a-header {
  color: black;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
  outline:none !important;
  box-shadow: none !important;

}

.a-header:hover {
  font-weight: bold;
  color: #00ff66;
  /* box-shadow: none !important; */

}

.a-header:focus{
  outline:none !important;
  box-shadow: none !important;

}

.a-header:active{
  outline:none !important;
  box-shadow: none !important;

}

.a-header.active:focus{
  outline:none !important;
  box-shadow: none !important;

}

.button {
  outline: none !important;
}

.container-account-nav {
  font-family: 'Roboto Condensed';
  background-color: f9f9f9;
  color: royalblue;
  font-size: 12px;
  letter-spacing: .86px;
  font-weight: bold;
  font-stretch: condensed;
}

.a-header-nav {
  color: royalblue;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
  outline:none !important;
  box-shadow: none !important;
}

.a-header-nav:hover {
  font-weight: bold;
  color: black;
  /* box-shadow: none !important; */

}

.a-header-nav:focus{
  outline:none !important;
  box-shadow: none !important;

}

.a-header-nav:active{
  outline:none !important;
  box-shadow: none !important;
}

.a-header-nav.active:focus{
  outline:none !important;
  box-shadow: none !important;
}