.signin-form {
	min-width: 300px;
	max-width: 800px;
	height: 100%;
	margin: auto;
}

.container-signin {
  position: relative;
  font-family: "Roboto Condensed";
  background-color: #F9F9F9;
  color: black;
  width: 100vw;
  max-width: 100%;
  height: 100vh;
  min-height: 650px;
}

.container-signin-form {
	max-width: 95vw;
	margin: auto;
	align-items: center;
}

.signin-title {
	font-family: Quantico;
	font-size: 28px;
	font-weight: bold;
	margin-bottom: 0;
	text-align: center;
	width: 100%;
}

.form-group-signin {
  margin: auto;
	padding: 5px;
}

.signin-button {
  background-color: #00ff66 !important;
  font-family: 'Roboto Condensed' !important;
  font-size: 16px !important;
  font-weight: bold !important;
  letter-spacing: 1.6px !important;
  color: #121212 !important;
  border: 0 !important;
	border-radius: 0 !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.06) !important;
	width: 100%;
	height: 54px;
	padding: 0 !important;
	@media screen and (max-width: 420px) {
		height: 44px;
		font-size: 14px !important;
		font-weight: bold !important;
		letter-spacing: 1.4px !important;
	}
}

.signin-button:hover {
  background-color: #00E35B !important;
}


.logo-image {
  background-image: url("../../assets/images/ddp-logo.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-color: #f9f9f9 !important;
  width: 100%;
  height: 64px;
  border: none;
  margin-top: 20px;
}

.col {
  padding-left: 0px !important;
  padding-top: 0px !important;
  margin-top: 0px !important;
}

.col-text-bold {
  font-family: Quantico;
  font-size: 16px;
  font-weight: bold;
  color: #121212;
  width: 100%;
  line-height: 1.13;
}

.col-text {
  font-family: Roboto Condensed;
  font-size: 14px;
  font-stretch: condensed;
  color: #121212;
  text-align: left;
  width: 100%;
}

.col-text-maxwidth {
  max-width: 350px !important;
  border: 1px solid #f9f9f9 !important;
	-webkit-appearance: none !important;
	-moz-appearance: textfield !important;
}

.col-text-div {
  max-width: 350px !important;
  font-family: Roboto Condensed;
  font-size: 14px;
  font-stretch: condensed;
  color: #121212;
  text-align: left;
  font-weight: bold;
}

p {
  margin-bottom: 12px;
}


.intro-listgroup {
  background-color: #f9f9f9;
  border: none !important;
  appearance: none !important;
  width: 100%;
  padding: 0;
}

.intro-col {
  border: none !important;
  appearance: none !important;
  text-align: left;
  padding: 0.25rem !important;
  padding-left: 0.75rem !important;
}

.intro-heading {
  font-family: Quantico;
  font-size: 32px;
  font-weight: bold;
  color: #121212;
  border: none !important;
}


.intro-row {
  font-family: "Roboto Condensed";
  font-size: 14px;
  font-stretch: condensed;
  color: #121212;
  text-align: left;
  width: 100%;
  padding: 0;
}

.form-group-signup {
  margin-top: 10px;
  margin-left: 15%;
}

.field-title {
  font-family: Quantico;
  font-size: 28px;
  font-weight: bold;
  line-height: 1.25;
  color: #121212;
  display: inline;
}

.field-input {
  color: #121212;
  background-color: #f9f9f9 !important;
  -webkit-appearance: none !important;
  -moz-appearance: textfield !important;
  border: none !important;
  outline: none !important;
  appearance: none !important;
  display: inline;
  padding-left: 0px !important;
}

.field-input::placeholder {
  text-transform: capitalize !important;
}

.uppercase-text {
  text-transform: uppercase;
}

.field-input:focus {
  color: #121212;
  border-color: #f9f9f9 !important;
  box-shadow: none !important;
}
