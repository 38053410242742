body {
  margin: 0;
  font-family: 'Roboto Condensed', 'Oswald', 'Helvetica Neue', 'sans-serif';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f9f9f9;
};

code {
  font-family: 'Oswald', 'Courier New';
}

.container {
  padding: 0 !important;
  margin: 0;
  font-family: 'Roboto Condensed', 'Oswald', 'sans-serif';
  background-color: #f9f9f9;
}
.container-fluid {
  padding: 0 !important;
  font-family: 'Roboto Condensed', 'Oswald', 'Quantico' !important;
  background-color: #f9f9f9;
}

.text-area {
  background-color : #f9f9f9 !important;
  border : 0px !important;
}

.next-button {
  background-color: #00ff66 !important;
  font-family: 'Roboto Condensed' !important;
  font-size: 16px !important;
  font-weight: bold !important;
  font-stretch: condensed !important;
  letter-spacing: 1.6px !important;
  color: #121212 !important;
  outline: none !important;
  border: none !important;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.06) !important;
  margin-top: 15px !important;
  margin-right: 10px !important;
}

.next-button:hover {
  background-color: #00E35B !important;
}

.save-btn {
  margin-top: 15px;
  margin-right: 10px;
}

.text-invalid {
  color: red;
}

.form-heading {
  font-family: 'quantico';
  font-size: 32px ;
  color: black;
  font-weight: bold;
}

.form-subheading {
  font-family: 'Roboto Condensed';
  font-size: 15px;
  line-height: 19px;
  color: black;
  text-align: justify;
  font-stretch: condensed;
}

.form-altheading {
  font-family: 'Quantico';
  font-size: 18px;
  line-height: 24px;
  color: black;
  font-weight: bold;
}

@media (max-width: 716px) {
  .form-altheading {
    text-align: center;
  }
  .small-center {
    text-align: center;
  }
}

.white-background {
  background-color: white;
}

.clear-overlay {
  width: 48px;
  height: 20px;
  border: none;
  float: right;
  position: sticky;
}

.clear-button {
  background-color:white !important;
  font-family: 'Roboto Condensed' !important;
  font-size: 12px;
  width: 42px;
  color: #121212 !important;
  border: 1px #f9f9f9;
  border-radius: 5px !important;
  height: 20px;
  cursor: pointer;
}

.clear-button:hover {
  background-color:f9f9f9 !important;
}

.form-control {
  font-family: 'Roboto Condensed';
  font-size: 18px !important;
  line-height: 26px;
  color: black;
  max-width: 320px;
  width: 100%;
  font-stretch: condensed;
}


.form-control::-webkit-outer-spin-button,
.form-control::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.form-check-green {
  font-family: 'Roboto Condensed';
  font-size: 13px !important;
  line-height: 16px;
  color: black;
  max-width: 490px;
  width: 100%;
  font-stretch: condensed;
}

.form-check-black-label {
  font-family: 'Roboto Condensed';
  font-size: 13px !important;
  line-height: 16px;
  color: black !important;
  font-stretch: condensed;
  text-align: center;
}

.form-check-box-green {
  height: 18px;
  width: 18px;
  padding-right: 2px;
}

.form-check-box-green:checked:after {
  font-size: 19px;
  content:  '\2714';
  background-color: #00ff66 !important;
  color: #00ff66 !important;
  border: 1px #00ff66 !important;
  position: absolute;
	top: 0px;
	left: 0px;
}

.no-border {
  border: 0 !important;
  -webkit-appearance: none;
}

.text-field-bold {
  font-family: 'Roboto Condensed';
  font-size: 18px;
  font-weight: bold;
  line-height: 26px;
  color: black;
  max-width: 320px;
  width: 100%;
  font-stretch: condensed;
}

.confirm-container {
  align-items: center;
  margin: auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  min-height: 30vh;
  justify-content: center;
  align-items: center;
  text-align: center;
  font-family: "Roboto Condensed";
  font-size: 14px;
}

